@font-face {
  font-family: 'Sharp Sans';
  src: url(bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url(black.otf);
  font-weight: 900;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url(semibold.otf);
  font-weight: 600;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url(extrabold.otf);
  font-weight: 800;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url(medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url(bold.otf);
  font-weight: bold;
}
