$theme-colors: (
  "primary": #20276a,
  "secondary": #f89a4c,
  "info": #0099ff,
);

@import "~bootstrap/scss/bootstrap";
@import "~swiper/css/swiper.css";

$primary: #20276a;
$secondary: #f89a4c;
$info: #0099ff;
$blue: #4364f7;

$hoverBgColor: #503bff;
$hoverBgColor2: #8293ff;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth !important;
}

html,
body {
  width: auto !important;
  overflow-x: hidden !important;
}

.partner-section {
  margin: 5% 0;

  h2 {
    color: #20276a;
    text-align: right;
    font-size: 2.8em;
    font-weight: bold;
    padding: 0 8%;
  }

  p {
    text-align: right;
    font-size: 1.25em;
    line-height: 1.6;
    padding: 0 8%;
  }

  h4 {
    margin-top: 30%;
    font-weight: bold;
  }
}

.dialogbox .body {
  position: relative;
  max-width: 300px;
  height: auto;
  margin: 20px 10px;
  padding: 20% 5%;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #f89a4c;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.5) 0px 18px 36px -18px;
}

.body .message {
  min-height: 100px;
  border-radius: 2px;
  font-family: Arial;
  font-size: 1.15em;
  line-height: 1.6;
  color: #f89a4c !important;
  // color: #343a40 !important;
  // text-align: justify;
  // text-justify: initial;
}

.tip {
  width: 0px;
  height: 0px;
  position: absolute;
  background: transparent;
  border: 10px solid #f89a4c;
}

.tip-down {
  bottom: -20px;
  left: 140px;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.tip-quote {
  position: absolute;
  right: 120px;
  top: 25px;
  font-size: 70px;
  overflow: hidden;
  line-height: 1;
  opacity: 0.5;
  font-weight: 700;
}

.the_input_form {
  width: 100%;
  min-height: 200px;
  overflow: hidden;

  h5 {
    width: 100%;
    padding: 2% 0;
    background-color: #3741a0;
    margin-bottom: 5%;
    color: white;
  }

  .ins-options {
    li {
      display: block;
      font-size: 0.7em;
      text-align: left;
      margin: 2% 0;

      label {
        cursor: pointer;
      }

      &:nth-child(1) {
        color: red;
        color: #3741a0;
        font-size: 0.9em;
      }
    }
  }

  .input-box {
    width: 100%;
    height: 45px;
    margin: 5% 0 8% 0;
    // border: 1px solid red;

    span {
      float: left !important;
      font-size: 0.7em;
      margin-bottom: 2%;
      color: #3741a0;
      font-weight: 500;
    }

    select {
      width: 100%;
      cursor: pointer !important;
      padding: 2%;
      font-size: 0.8rem;
    }

    input[type="text"],
    textarea {
      display: block;
      width: 100%;
      margin: 5% 0;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #768898;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer !important;
      padding: 0 3%;
      margin-bottom: 5%;
    }

    input[type="text"]:focus {
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid red !important;
    }

    input[type="submit"],
    button {
      margin-top: 2%;
      margin-bottom: -2%;
      width: 50%;
      padding: 2.5% 0;
      background-color: #3741a0;
      font-size: 0.8em;
      cursor: pointer;
      border-radius: 25px;
      border: 1px solid #ced4da;
      color: white;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.talk-to-us > p {
  text-align: left;
  font-size: 0.75em;
  line-height: 1.8;
}

.talk-to-us > p span {
  font-size: 1.4em;
  font-weight: bold;
}

.talk-to-us > select,
input[type="text"] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 3px);
  margin: 3% 0;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer !important;
}

.talk-to-us > select {
  padding: 0.375rem 0.45rem;
}

.talk-to-us > input[type="text"] {
  padding: 0.375rem 0.75rem;
}

.the-search {
  color: red;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 850px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 850px;
}

.just-ify {
  line-height: 1.6em;
  text-align: justify;
  text-justify: initial;
}

body {
  margin: 0px;
  font-family: "Sharp Sans";
  font-size: 13px;
  font-weight: 600;
  color: #878a91;
  // overflow-x: hidden;
}

// .page {position: relative;top: 100px;left: 0;right: 0;}

// .fade-appear,.fade-enter{opacity: 0;z-index: 1;}

// .fade-appear-active,.fade-enter.fade-enter-active {opacity: 1;transition: opacity 450ms linear 250ms;}

// .fade-exit {opacity: 1;}

// .fade-exit.fade-exit-active{opacity: 0;transition: opacity 150ms linear;}

.intro-header {
  width: 100%;
  // min-height: 700px;
  position: relative;

  .swiper-section {
    position: absolute;
    height: 100% !important;
    top: 0;
    left: 0;

    .swiper-container {
      height: 100%;

      .swiper-wrapper {
        .swiper-slide {
          overflow: hidden;
          position: relative;

          img {
            width: 100vw;
            // height: 100%;
            object-fit: fill;
          }
        }
      }
    }
  }

  // .swiping-layer {

  // }
}

.blog-section {
  padding: 0 0 5% 0;

  .region {
    padding: 1rem 0rem 0rem 0rem;
    background-color: rgb(251, 196, 150, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      span {
        color: #0099ff;
        font-size: 1.1em;
        text-decoration: underline;
      }
    }

    select {
      margin: -0.8% 0% 0 1%;
      background-color: transparent;
      border: 0 none;
      cursor: pointer;
      font-size: 0.8em;
    }
  }

  .previews {
    width: 100%;
    min-height: 1000px;

    .lg-bx {
      width: 100%;
      height: 429px;
      margin-bottom: 3%;

      .child-title {
        padding: 0 5%;

        span {
          color: #ffffff;
          font-weight: bold;
          font-size: 1em;
          background-color: #17222b;
          padding: 0 2%;
        }
      }

      .child {
        color: #ffffff;
        width: 100%;
        height: 100%;
        padding: 3% 4% 1% 4%;
        display: flex;
        background-image: linear-gradient(
          rgba(38, 38, 38, 0.2),
          rgba(82, 82, 82, 0.2),
          rgba(37, 37, 37, 0.2),
          #070707
        );

        .child-title {
          padding: 0 5%;
          align-self: flex-start;

          span {
            color: #ffffff;
            font-weight: bold;
            font-size: 1em;
            background-color: #17222b;
            padding: 0 2%;
          }
        }

        .text-content {
          align-self: flex-end;

          span {
            margin-bottom: 5%;
          }

          h3 {
            a {
              color: #fff;
              font-size: 28px;
              line-height: 45px;
              font-weight: 700;
              text-decoration: none;
            }
          }

          p {
            font-size: 15px;
            line-height: 25px;
            font-weight: 400;

            a {
              color: #ffffff;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .classified {
      width: 100%;

      h4 {
        font-weight: 500;
      }

      .classified-btns {
        padding: 0;
        margin: 15px 0 30px 0;
        border: none;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        li {
          margin-right: 2%;

          button {
            cursor: pointer;
            display: inline-block;
            padding: 6px 30px;
            font-size: 1.2em;
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 0.15);
            color: #17222b;
            background: #fff;
            transition: all 0.3s;
            border-radius: 1px;
            box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);

            // font-size: 12px;
            // font-weight: 500;
            // color: #000;
            // text-transform: uppercase;
            // line-height: 12px;
            // padding: 12px;
            // width: 100%;
            // text-align: center;
            // cursor: pointer;
          }

          button.active {
            color: #ffffff;
            background-color: #0366d6;
            border-color: #005cc5;
            transition: all 0.3s ease 0s;
          }

          button:hover {
            color: #ffffff;
            background-color: #0366d6;
            border-color: #005cc5;
            transition: all 0.3s ease 0s;
          }
        }
      }

      .classified-category {
        margin-top: 2%;

        .classified-category-article {
          margin-bottom: 5%;
          width: 100%;
          height: 400px;

          img {
            width: 100%;
            height: 230px !important;
          }

          .text-content {
            padding: 5% 2% 0 2%;

            span,
            h6 {
              padding: 3% 0;
            }

            h6 {
              a {
                font-size: 1.1em;
                text-decoration: none;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .share-icons {
    img {
      width: 25px;
    }

    div {
      border-radius: 4px;
      margin-right: 10px;
      overflow: hidden;
      cursor: pointer;
    }
  }

  .single_article {
    margin-top: 2%;

    .single_article_content {
      .bread-crumb {
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 3%;

        li {
          font-size: 1em;
          display: inline;
          margin-right: 1%;

          a {
            color: #17222b;
            text-decoration: none;
          }
        }
      }

      .category {
        line-height: 1.8;
        padding: 1% 0;
        font-size: 1.1em;
        color: #17222b;
        text-transform: uppercase;
      }

      .written {
        line-height: 1.8;
        padding: 1% 0;
        font-size: 1.1em;
        margin-bottom: 3%;
      }

      p {
        line-height: 1.8;
        padding: 1% 0;
        font-size: 1.1em;
        color: #17222b;
      }

      h2 {
        margin-top: 2%;
        font-weight: bold;
        color: #17222b;
        line-height: 1.3;
      }

      h4 {
        font-weight: bold;
        color: #17222b;
        line-height: 1.3;
      }

      img {
        margin-top: -3%;
        margin-bottom: 1%;
      }

      .date-author-comments {
        list-style-type: none;
        margin-top: 1%;

        li {
          display: inline-block;
          margin-right: 3%;
          font-size: 1.1em;
        }
      }
    }
  }

  .side-bx {
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 1% 0 1% 0;
        width: 100%;
        height: 100px;
        border: 0 none;
        border-bottom: 1px dashed black;
        margin-bottom: 2%;
        display: flex;
        justify-content: flex-start;

        .img_box {
          width: 30%;
          height: 75px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          cursor: pointer;
        }

        .child {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
        }

        .img_box:hover .child,
        .img_box:focus .child {
          -ms-transform: scale(1.2);
          -moz-transform: scale(1.2);
          -webkit-transform: scale(1.2);
          -o-transform: scale(1.2);
          transform: scale(1.2);
        }

        .img_box:hover .child:before,
        .img_box:focus .child:before {
          display: block;
        }

        .img_box:hover a,
        .img_box:focus a {
          display: block;
        }

        .child:before {
          content: "";
          display: none;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .text-content {
          padding-left: 3%;
          width: 70%;
          height: 80px;

          span {
            margin-top: -2%;
            font-size: 0.75em;
            margin-bottom: 3%;
            font-weight: 500;
          }

          h6 {
            a {
              color: #17222b;
              font-weight: 500;
              padding: 1% 0 0 0;
              font-size: 0.9em;
              font-weight: 550;
              line-height: 1.5;
              cursor: pointer;
              text-decoration: none;
            }
          }
        }
      }

      & li:last-child {
        border-bottom: 0 none;
      }
    }
  }

  .news_letter {
    background: #e9eaeb;
    padding: 10% 5%;
    width: 100%;
    min-height: 100px;
    border: 0 none;

    h5 {
      line-height: 1.6;
    }

    h6 {
      font-weight: bold;
      color: #17222b;
    }

    input[type="text"] {
      height: 40px;
      width: 75%;
      border-radius: 0 !important;
      border: 0 none;
      padding: 0 2%;
    }

    input[type="submit"] {
      height: 40px;
      width: 25%;
      border-radius: 0 !important;
      font-size: 1em;
      cursor: pointer;
      color: #fff;
      background: #1091ff;
      border: none;
    }
  }

  .sliding-buttons {
    display: flex;
    justify-content: flex-end;
    justify-content: space-between;

    button {
      cursor: pointer;
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border: 1px solid #c1c4c6;
      color: #c1c4c6;
      background: #fff;
      transition: all 0.3s;
    }

    button:hover {
      color: #ffffff;
      background-color: #0366d6;
      border-color: #005cc5;
      transition: all 0.3s ease 0s;
    }
  }

  .multiple-sliders {
    padding: 2% 0;
    width: 100%;
    margin: 3% 0;
    background-color: red;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
}

.flag-icon {
  img {
    width: 15px !important;
    height: 12px !important;
  }
}

.wrapper-hght {
  height: 290px;
}

.page {
  min-height: 20vh;
  position: relative;

  .top-layer {
    position: absolute;
    z-index: 9999999;
  }
}

.learn-more-section {
  padding: 5% 0 !important;

  .learn-more {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .learn {
      border-radius: 15px;
      // margin-bottom: 1%;
      width: 300px;
      min-height: 230px;
      cursor: pointer;
      // transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: 0.3s all ease-in-out;
      transform: scale(0.95);
      padding: 3%;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

      p {
        line-height: 1.8;
        color: grey;
      }

      img {
        width: 45px;
        height: 45px;
      }

      h5 {
        margin-top: 8%;
        font-weight: 700;
        color: $secondary;
      }

      a {
        background-color: transparent;
        border: 0 none;
        cursor: pointer;
        text-decoration: underline;
      }

      &:hover {
        box-shadow: none;
        transform: translateY(0) scale(1.2);
        transform: scale(0.99);
        p,
        h5,
        a {
          color: white;
          // transition: .3s all ease-in-out;
        }
      }

      &:hover {
        background: linear-gradient(130deg, #2c3591 0%, $primary 100%);
      }

      &:nth-child(3) {
        margin-top: 3%;
      }

      &:nth-child(4) {
        margin-top: 3%;
      }
    }
  }
}

// .flag {
//   width: 100px;
//   height: 100px;
//   border: 1px solid transparent;
//   border-radius: 50%;
// }
.quote-data {
  padding: 0 5%;
}

.nav-top {
  font-size: 10px;

  img {
    width: 20px;
    height: 18px;
  }
}

.rider-text {
  font-weight: 50;
  font-size: 12px;
  color: #9799ea;
}
.main-logo {
  width: 130px;
  margin-left: 13px;
}

.countdown {
  width: 100%;
  background-color: #0069ff;
  color: #ffffff;
  text-align: center;
  padding: 0.5% 0 0 0;
  font-weight: bold;
}

.nav {
  .nav-link {
    height: 100%;
    color: $dark !important;
    position: relative;
  }
  .active {
    color: $primary !important;
  }
  .active::before {
    content: "";
    bottom: 0px;
    left: 13px;
    height: 5px;
    position: absolute;
    width: calc(100% - 26px);
    position: absolute;
    background-color: $secondary;
  }

  .nav-link:hover::before {
    content: "";
    bottom: 0px;
    left: 13px;
    height: 5px;
    position: absolute;
    width: calc(100% - 26px);
    position: absolute;
    background-color: $secondary;
  }
}

.header-section {
  min-height: 100vh;

  .intro-banner {
    position: relative;
  }

  // .intro-banner::before {
  //   content: '';
  //   background-color: rgba($dark, 0.5);
  //   width: 50%;
  //   position: absolute;
  //   height: 100%;
  // }

  .header-content {
    width: 97vw;
    background-color: #f6f6f6;
  }

  .header-title {
    font-size: 60px;
    font-weight: 800;
    line-height: 1.1;
    color: white;
    white-space: nowrap;
  }

  .header-subtext {
    font-size: 22px;
    font-weight: 600;
    color: white;
  }
}

.insurace-list {
  background-color: #eeeff0;
  img {
    height: 80px;
  }

  overflow-x: auto;
}

.steps-section {
  position: relative;
  min-height: 100vh;
  background-image: url(../img/front_banner/banner6.jpg);
  background-position: center;
  background-size: cover;

  &::before {
    content: "";
    background-color: rgba(#9797aa, 0.2);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
  }
  .section-content {
    position: static;
    z-index: 1;
  }

  .section-text {
    width: 500px;
    max-width: 100vw;
  }

  .steps {
    width: 800px;
    max-width: 100vw;

    h5 {
      font-size: 30px;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.9);
    }

    .col-md-4 {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 266px;
      .txt {
        position: static;
        z-index: 1;
      }
    }

    .col-md-4::before {
      z-index: 0;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      background-color: rgba($primary, 0.6);
      border-radius: 50%;
      transform: scale(1.1);
    }

    .centered::before {
      background-color: rgba($primary, 0.8);
    }
  }
}

.title {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;
}

.text-bold {
  font-weight: 700;
}

img.quote {
  width: 60px;
}

.quote-text {
  width: 500px;
  max-width: 100%;
}
.quote-user {
  position: relative;
  font-size: 12px;
  display: inline-block;
  color: #a2a2a2;
}

.quote-user::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 1px;
  background-color: #a2a2a2;
  top: 50%;
  transform: translateX(-150%);
}

.quote-user::after {
  content: "";
  right: 0px;
  position: absolute;
  width: 50px;
  height: 1px;
  background-color: #a2a2a2;
  top: 50%;
  transform: translateX(150%);
}

.text-subtext {
  color: #a2a2a2 !important;
}

.text-mute {
  color: #a2a2a2 !important;
}

.footer {
  background-color: #22252a;
  bottom: 0;
  width: 100%;

  .country {
    width: 70%;
    display: flex;

    select {
      border: 0 none;
      background-color: #22252a;
      color: white;
      padding-top: 4%;
      padding-left: 5%;
      cursor: pointer;
    }
  }

  .footer-cta {
    width: 450px;
    max-width: 100%;

    .btn {
      border-radius: 0px;
      padding-top: 22px;
      padding-bottom: 22px;
      transition: all 0.3s;
    }
  }
  .text-subtext {
    color: #b6b8be !important;
  }

  .footer-main {
    min-height: 320px;
  }
  .footer-sub {
    border-top: 1px solid #484848;
    font-size: 13px;
  }
  .arrow {
    position: absolute;
    right: 30px;
    top: 25%;
    // transform: translateY(-50%);
  }
}

.beadcrumb {
  font-size: 12px;
  color: #9799ea;
}

.quote-inputs {
  font-size: 20px;
}

.quote-input {
  // float: left;
  text-align-last: center;
  background-color: transparent;
  border: 0px;
  // width: 100px;
  color: #9799ea;
  text-align: center !important;

  &:focus {
    border: 0px;
    outline: 0px;
  }

  &::placeholder {
    color: #9799ea;
  }
}

.mute {
  opacity: 0.6;
}

.input-label {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;

  &::after {
    content: "";
    background-color: #9799ea;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
}

.option-label {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  // font-weight: normal;

  // div{
  //   width: 75%;
  // }

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="checkbox"]:checked + div,
  [type="checkbox"]:not(:checked) + div {
    position: relative;
    padding-right: 50px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $blue;
  }
  [type="checkbox"]:checked + div:before,
  [type="checkbox"]:not(:checked) + div:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #9799ea;
    border-radius: 100%;
    background: transparent;
  }
  [type="checkbox"]:checked + div:after,
  [type="checkbox"]:not(:checked) + div:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #9799ea;
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="checkbox"]:not(:checked) + div:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked + div:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + div,
  [type="radio"]:not(:checked) + div {
    position: relative;
    padding-right: 50px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #9799ea;
  }
  [type="radio"]:checked + div:before,
  [type="radio"]:not(:checked) + div:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #9799ea;
    border-radius: 100%;
    background: transparent;
  }
  [type="radio"]:checked + div:after,
  [type="radio"]:not(:checked) + div:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #9799ea;
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + div:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + div:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.th-tab {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  padding-right: 22px;

  .ico {
    position: absolute;
    right: 4px;
  }
}

.th-text {
  font-size: 13px;
  line-height: 1.5;
}

.desc-text {
  font-size: 1em;
  line-height: 2;
}

.show_more {
  font-size: 11px;
  border-radius: 0px;
  white-space: nowrap;
  font-weight: 600;
}

tbody::after {
  content: "";
  display: block;
  height: 5px;
}

.cntr {
  display: grid;
  place-items: center;
}

.loading {
  width: 30%;
  min-height: 100px;
  margin: 3% auto;

  h5 {
    text-align: center;
    margin-bottom: 10%;
  }

  .slide-screen {
    width: 30%;
    height: 100px;
    margin: 0 auto;

    .companies {
      width: 100%;
      height: 60px;

      img {
        padding: 1%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: grayscale(100%);
      }
    }
  }
}

.quote-table {
  margin-top: -40px;

  .thead {
    border-radius: 5px !important;
    overflow: hidden;

    .header-card {
      margin-bottom: 10px !important;
      position: relative;
      margin-top: -50px;
      border-top: 0px;
      background-color: white;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 80px;
      border-radius: 5px !important;
      overflow: hidden;
    }
  }

  td {
    position: relative;
    vertical-align: middle;

    [type="checkbox"]:not(:checked) + div {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: $primary;
    }
    [type="checkbox"]:checked + div:before,
    [type="checkbox"]:not(:checked) + div:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 2px solid $primary;
      border-radius: 100%;
      background: transparent;
    }
    [type="checkbox"]:checked + div:after,
    [type="checkbox"]:not(:checked) + div:after {
      content: "";
      width: 10px;
      height: 10px;
      background: $primary;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
  }

  .option-label div {
    padding-left: 30px !important;
  }

  .option-label div::before {
    transform: translateY(5px);
  }
  .option-label div::after {
    top: 9px !important;
  }
}

.img-ico {
  width: 67px;
}

.text-highlight {
  color: #9799ea;
}

.text-highlight.price {
  font-size: 18px;
  line-height: 1.2;
}

.list-price .text-muted {
  font-size: 11px;
}

.table .action {
  font-size: 10px;
  border-radius: 0px;
  white-space: nowrap;
  font-weight: 600;
}
.btn {
  transition: all 0.3s;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(#9799ea, 0.03);
}

.header-title {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.1;
  color: #454556;
}

.side-menu {
  color: #454556;

  .links {
    font-size: 16px;
  }
}

.header-top {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 150px;
    object-fit: cover;
  }
}

.compare-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $primary;
  font-weight: 700;
}

.compare-item.premium {
  height: 60px;
  font-size: 28px;
  // margin-top: -30px;
}

.premium-menu {
  height: 60px;
  margin: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.compare-card {
  .links {
    // background-color: pink;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $dark;
  }
}

.lead_form.modal {
  background-color: rgba(black, 0.8);

  .title {
    font-weight: 600;
  }

  .modal_dialog {
  }

  .modal-content {
    background-color: transparent;
    border: 0px;
    width: 425px;
    max-width: 100%;

    .form-control {
      height: 50px;
    }
  }

  .form-control {
    border-radius: 0px;
  }

  .btn {
    border-radius: 0px;
    font-weight: 600;
    width: 107px;
  }
}

.home-quote {
  position: relative;

  //
  .betlist-options div {
    width: 75%;
  }

  .form-content {
    font-size: 20px;
    line-height: 1.8;
  }

  .intro-title {
    font-size: 40px;
    line-height: 1.1;
    font-weight: 800;
    max-width: 300px;
  }

  .content {
    position: relative;
    z-index: 10;
  }
  &::before {
    content: "";
    background-color: #f6f6f6;
    height: 100%;
    width: 50vw;
    position: absolute;
    z-index: 0;
  }

  .btn {
    font-weight: 600px !important;
  }

  .quote-input {
    font-weight: 600;

    color: $primary;
    text-align: center !important;
    &:focus {
      border: 0px;
      outline: 0px;
    }

    optgroup {
      font-size: 18px;
      font-family: arial;
      // font-family: "Sharp Sans" !important;
    }

    &::placeholder {
      color: $primary;
      font-weight: 600;
    }
  }

  .input-label::after {
    background-color: $primary;
  }

  .option-label {
    &::after {
      background-color: $primary !important;
    }
  }

  .option-label div {
    color: $primary !important;
  }

  [type="checkbox"]:not(:checked) + div {
    color: $primary !important;
  }
  [type="checkbox"]:checked + div:before,
  [type="checkbox"]:not(:checked) + div:before {
    border: 2px solid $primary !important;
  }
  [type="checkbox"]:checked + div:after,
  [type="checkbox"]:not(:checked) + div:after {
    background: $primary !important;
  }

  [type="radio"]:not(:checked) + div {
    color: $primary !important;
  }
  [type="radio"]:checked + div:before,
  [type="radio"]:not(:checked) + div:before {
    border: 2px solid $primary !important;
  }
  [type="radio"]:checked + div:after,
  [type="radio"]:not(:checked) + div:after {
    background: $primary !important;
  }

  .footer-text {
    font-size: 11px;
  }

  .area {
    width: 150px;
  }
}

.comparison-page {
  // font-size: 10px;
  .col-md-20 {
    width: 20%;
  }

  .col-md-80 {
    width: 80%;
    position: relative;
  }

  .menu-title {
    font-size: 26px;
  }

  .menu {
    border: 1px solid #f6f6f6;
    // padding: 20px;
    height: 90%;
    border-radius: 5px;
    background-color: $primary;

    .menu-header {
      margin: 20px;
    }

    .menu-title {
      font-size: 26px;
      color: #9498e6;
    }

    .menu-subtitle {
      font-size: 17px;
      font-weight: 600;
      color: #9498e6;
    }
  }

  .menu-link {
    padding: 15px;
    color: white;

    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .menu-link:hover {
    // color: #9498e6;
    background-color: rgba(#9498e6, 0.4);
  }
  .menu-link.active {
    // color: #9498e6;
    background-color: #9498e6;
  }
}

.comaprison-table {
  .company-logo {
    height: 40px;
    margin: 0 auto;
  }

  .price {
    font-size: 35px;
    font-weight: 700;
    color: $primary;
  }

  th {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.text-default {
  // color: #878a91;
}

.plan {
  .header-content::before {
    content: "";
    // background-image: url(../img/plan1.png) !important;
    background-position: center;
    background-size: cover;
    width: 50vw;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  .header-content {
    .row::before {
      content: "";
      // background: rgba($dark, 0.5) !important;
      background-position: center;
      background-size: cover;
      width: 50vw;
      position: absolute;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 0;
    }
  }

  .plan-banner {
    background: none !important;
  }
}

.blog {
  color: #464756;
  font-size: 1rem;
  font-weight: 500;
  .banner {
    height: 400px;
    object-fit: cover;
  }

  .share-icons {
    img {
      width: 25px;
    }
    div {
      border-radius: 4px;
      margin-left: 20px;
      overflow: hidden;
    }
  }

  .recommended {
    img {
      border-radius: 5px;
    }
  }
}

.container-50 {
  width: 650px;
  max-width: 100%;
}

.landing-swiper {
  overflow: hidden;

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-content {
    z-index: 3;
  }

  .bg-img {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .cover {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba($dark, 0.5);
  }

  .home-pagination {
    transform: scale(1.3);
    bottom: 58px;
    .swiper-pagination-bullet {
      background-color: white;
      transition: all 1.5s;
    }

    .swiper-pagination-bullet-active {
      width: 30px;
      border-radius: 10px;
    }
  }
}

.interests-swiper {
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
  }
}

.interests-slide {
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    transition: all 0.5s;
    position: relative;
    z-index: 0;
  }

  &::before {
    z-index: 1;
    opacity: 0;
    transition: all 0.5s;
    transform: translateY(30%);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      rgba(black, 0),
      rgba(black, 0.3),
      rgba(black, 0.7),
      rgba(black, 0.9)
    );
  }

  .text-drawer {
    opacity: 0;
    transition: all 0.5s;
    transform: translateY(30%);
    position: absolute;
    opacity: 0px;
    bottom: 0px;
    color: white;
    z-index: 2;
  }
}

.interests-slide:hover {
  &::before {
    opacity: 1;
    transform: translateY(0px);
  }
  .text-drawer {
    opacity: 1;
    transform: translateY(0px);
  }

  img {
    transition: all 10s;
    transform: scale(1.2);
  }
}

.nav-padding {
  height: 128px;
}

.nav {
  position: fixed;
  top: 0px;
  z-index: 800;
  background-color: white;
  width: 100vw;
}

.cookie-policy {
  // background-color: #eeeff0;
  color: #5e5f62;
}

.cookie-popup {
  pointer-events: none;
  position: fixed;
  bottom: 0px;
  width: 100vw;
  background-color: #eeeff0;
  z-index: 1000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(50%);
  transition: all 0.5s;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btn {
    font-weight: 600;
    white-space: nowrap;
  }

  &.active {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: all;
  }
}

.cursor {
  cursor: pointer;
}

.modal.default {
  background-color: rgba(black, 0.5);
}

.relation-card {
  border: 2px dashed #e5e5e6;
  border-radius: 15px;
  label {
    font-size: 16px;
  }
}

.remove-dependant {
  position: absolute;
  right: 10px;
  top: 10px;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  // margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $primary;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.loarder-cover {
  position: absolute;
  background-color: rgba(white, 0.7);
  width: calc(100% - 30px);
  height: 100%;
  z-index: 10;
  transition: all 0.3s;

  .loader-holder {
    margin-top: 215px;
  }
}

.loarder-cover.hidden {
  opacity: 0 !important;
  pointer-events: none !important;
}

.quote-table {
  font-size: 12px;
}

.scope-dropdown {
  width: 80px;
  padding-right: 0px !important;
}

.frequency-dropdown {
  width: 100px;
  padding-right: 0px !important;
  border: 0 none;
}
.excess-dropdown {
  width: 150px;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 0px !important;
  border: 0 none;
}

.footer-header {
  font-size: 16px;
  font-weight: 600;
}

.footer-link {
  font-weight: 500;
  font-size: 13px;
}

.footer-logo {
  width: 150px;
  max-width: 100%;
}

.opacity-0 {
  opacity: 0;
}

.footer {
  .share-icons {
    img {
      width: 25px;
    }
  }
}

.partner-card {
  img {
    width: 130px;
    height: 100px;
    object-fit: cover;
  }
}

.nav-search {
  position: relative;

  .search-icon {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  ::placeholder {
    font-weight: 600;
    font-size: 13px;
  }
}

.quote-details {
  .sect {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    img {
      max-width: 100%;
      width: 130px;
      height: 100px;
      object-fit: contain;
    }

    .title-text {
      font-size: 25px;
      color: $primary;
      font-weight: bold;
      line-height: 1.1;
      margin-top: 8px;
    }
  }
}
.btn {
  font-weight: 600 !important;
}

.accordion {
  .accordion-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  td {
    width: 50%;
  }
}

.home-submit-btn {
  min-width: 225px;
  position: relative;
  text-align: center;

  .sk-cube-grid {
    position: absolute;
    left: 10px;
    transform: scale(0.7);
    .sk-cube {
      background-color: white;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.faq {
  padding-bottom: 5%;

  .title {
    h1 {
      font-size: 2.2em;
      font-weight: 600;
      color: $primary;
      // color: $secondary;
    }
  }

  section {
    // margin: 0 20rem 0 20rem !important;
    min-height: 200px;

    h4 {
      font-size: 1.6em;
      font-weight: 600;
      // color: #bd5a07;
      // color: #bd5a07;
      color: $primary;
      padding: 1rem 1rem 0.5rem 1rem;
    }

    ul {
      list-style-type: none;

      li {
        padding: 0 1rem 1rem 1rem;
        border-radius: 15px;

        .faq-title {
          display: flex;
          justify-content: space-between;
          padding: 0 1rem 0rem 0rem;
          color: $secondary;
          font-size: 1.2em;
          cursor: pointer;
        }

        .faq-body {
          padding: 0 1rem 0rem 0rem;

          p {
            padding-top: -3%;
            font-size: 1.05em;
            // color:#bd5a07;
            // color: #686b74 !important;
            color: #20276a !important;
            line-height: 1.8;

            .faq-li li {
              list-style-type: circle;
              color: red;
            }
          }
        }

        &.active {
          padding: 1rem 1rem 1rem 1rem;
          background-color: rgb(251, 196, 150, 0.1);
          // background-color: rgb(50,61,165);
          margin-bottom: 2%;
        }
      }
    }
  }
}

.faq-card {
  transition: all 0.3s;
  cursor: pointer;

  img {
    width: 60px;
    height: auto;
  }

  &:hover {
    background-color: rgba(black, 0.03);
  }
}

.faq-header {
  width: 525px;
}

.website-search-header {
  width: 100%;

  h3 {
    font-size: 1.5em;

    span {
      font-size: 1.4em;
      font-weight: bold;
    }
  }
}

.bold {
  font-weight: 600;
}

.ccn {
  min-width: 100%;
}

.distributor-card {
  .form-control {
    border-radius: 0 !important;
    height: 45px;
  }
}

.side-menu {
  opacity: 1;
  z-index: 10000;
  position: fixed;
  top: 100px;
  right: 0px;
  width: 200px;
}

.side-menu-bg {
  // background-color: rgba(black, 0.1);
  position: fixed;
  transition: all 0.5s;
  max-height: 0px;
  min-height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 9999;
}

.comaprison-table {
  .mnt {
    width: 230px !important;
  }
}

.alert {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba($primary, 0.2);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;

  .alert-title {
    font-size: 22px;
    font-weight: 600;
  }

  .content {
    border-radius: 10px;
    width: 500px;
    // min-height: 400px;
    max-width: 100%;
    box-sizing: border-box;
    transform: translateY(50px);
    transition: all 0.5s;
  }
  &.active .content {
    transform: translateY(0px);
  }

  .btn {
    min-width: 100px;
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }
}

.blur-alert {
  filter: blur(5px);
}

#nest1 {
  display: block;
  // position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 3px solid transparent;
  border-top-color: $primary;
  border-radius: 50%;
  -webkit-animation: spin7 1.5s ease infinite;
  animation: spin7 1.5s ease infinite;

  &:before {
    content: "";
    position: absolute;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: $primary;
    -webkit-animation: spin7 3s linear infinite;
    animation: spin7 3s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: $primary;
    -webkit-animation: spin7 1.5s ease infinite;
    animation: spin7 1.5s ease infinite;
  }

  @-webkit-keyframes spin7 {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes spin7 {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
}
.nest-parent {
  margin-top: 50px;
  transform: scale(1.3);
  // background-color: #f75f6d;
}

.popup {
  opacity: 0;
  max-height: 0px;
  // transform: scale(0.8);
  transition: all 0.3s;

  .popup-inner {
    border-radius: 10px;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    width: 450px;
  }

  &.show {
    opacity: 1;
    max-height: 175px;
    transform: scale(1);
  }
}

.notification-container {
  position: fixed;
  bottom: 15px;
  right: 0px;
  max-width: 100%;
}

.icon-hover {
  padding: 5px;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.notification-close {
  right: 20px;
  top: 20px;
  height: 32px;
  width: 32px;
}

.blog-content {
  line-height: 0.5;
  font-size: 1.05em !important;

  p {
    line-height: 2.1 !important;
  }

  ul {
    font-size: 1em !important;

    li {
      line-height: 2.1 !important;
    }
  }
}

.blog-title {
  position: relative;
  &::before {
    content: "";
    height: 26px;
    width: 13px;
    background-color: $secondary;
    margin-left: -25px;
    position: absolute;
  }
}

.blog-home {
  .intro-title {
    max-width: 100%;
  }
}

.about {
  .page-content {
    font-weight: normal;
  }

  header {
    height: 400px;
    .banner {
      position: absolute;
      z-index: 1;
    }
    .container {
      z-index: 20;
    }

    .about-title {
      font-weight: 800;
      font-size: 50px;
    }
  }
  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .sct-1 {
    width: 550px;
    max-width: 100%;
  }
}

.who-tab {
  .who-icon {
    width: 50px;
  }
}

.card-testimonial {
  height: 100px;
  width: 100%;
  background-color: blue;
}

.testimonial-card {
  color: white;
  border: 0px;
  text-align: left;

  .quote {
    position: absolute;
    right: 20px;
    top: 8px;
    font-size: 70px;
    overflow: hidden;
    line-height: 1;
    opacity: 0.5;
  }
}

.contact {
  .page-content {
    font-weight: normal;
  }
  .contact-card {
    .contact-icon {
      max-height: 50px;
    }

    .center {
      background-color: #191f5f;
    }
  }

  .sct-1 {
    width: 550px;
    max-width: 100%;
  }

  .contact-form {
    input,
    select {
      height: 50px;
      width: 100% !important;
    }
    .form-control {
      border-radius: 0px;
    }
  }
}

.health-insurance {
  color: #464756;

  header {
    height: 400px;
    .banner {
      position: absolute;
      z-index: 1;
    }
    .container {
      z-index: 20;
    }

    .about-title {
      font-weight: 800;
      font-size: 50px;
    }
  }

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .page-content {
    font-weight: normal;
  }
}

.tab-links {
  border-width: 2px !important;
  position: relative;

  .tab-link {
    font-size: 17px;
    cursor: pointer;
    font-weight: 600;
  }
}

.tab-link.active {
  position: relative;
  color: $primary;
  &::before {
    content: "";
    background-color: $secondary;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: -2px;
  }
}

.insurance-icon {
  height: 50px;
}

.compare-button {
  position: fixed;
  bottom: 20px;
  z-index: 20;
}

.faq-desc {
  // text-decoration: none !important;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 2; /* number of lines to show */
  // -webkit-box-orient: vertical;

  p:nth-child(2) {
    display: none;
  }

  p {
    text-decoration: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.dropdown-search-card {
  position: absolute;
  width: 100%;
  top: 40px;
  z-index: 1000;
}

.plan-your-trip {
  margin-bottom: 2%;

  .covid-banner {
    min-height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .covid-title {
      font-weight: 800;
      color: white;
      font-size: 45px;
      line-height: 1.2;
    }

    img {
      object-fit: cover;
      position: absolute;
    }
  }

  .home-quote {
    &::before {
      display: none;
    }
    .form-content {
      color: #878a91 !important;
    }
  }

  .blog-title {
    font-weight: 700;
  }

  .quote-title {
    font-weight: 800;
    font-size: 34px;
  }
  .service-title {
    position: relative;
  }

  .service-title::before,
  .service-title::after {
    content: "";
    position: absolute;
    height: 2px;
    top: 50%;
    width: calc(50% - 170px);
    background-color: #cbcbcc;
    left: 0px;
  }

  .service-title::after {
    right: 0px;
    left: unset;
  }
}

.explore-insurance {
  color: #000000;
  text-align: justify;
  text-justify: initial;

  ul {
    li {
      list-style: circle !important;
    }
  }

  .explore-banner {
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .explore-title {
      font-weight: 800;
      color: white;
      font-size: 35px;
      line-height: 1.4;
      padding: 13% 60% 1% 0;
      color: #3741a0;
    }

    .explore-sub-title {
      padding: 0% 63% 1% 0;
      line-height: 1.5;
    }

    img {
      object-fit: cover;
      position: absolute;
    }
  }

  // header{
  //   width: 100%;
  //   min-height: 400px;
  //   position: relative;
  // }

  .intro-icon {
    width: 100%;
    height: auto;
    padding: 3% auto;
    display: inline-block;
    text-align: center;
    color: #3741a0;
    text-decoration: none;
    cursor: pointer;

    h6 {
      font-weight: bold;
    }

    img {
      width: 80px !important;
      height: 80px !important;
    }
  }

  section {
    padding-top: 8%;
    padding-bottom: 5%;

    .main {
      display: flex;
      // flex-wrap:wrap;
      // justify-content: center;
    }

    .left {
      position: relative;
      width: 50vw;
      height: auto;
      padding: 3% 0 !important;

      p {
        line-height: 1.8;
        font-size: 1.05em;
      }

      ul {
        padding-left: 3%;
        list-style-type: circle;

        li {
          display: block;
          line-height: 2;
        }

        &:nth-child(2) {
          margin-top: -5%;
        }
      }

      button {
        padding: 0.75rem 3rem;
        border-radius: 25px;
        text-transform: uppercase;
        text-decoration: none;
        border: 0 none;
        font-weight: bold;
      }

      button.blue-btn {
        background-color: #3741a0;
        color: white;
      }

      button.white-btn {
        background-color: white;
        color: #3741a0;
      }
    }

    .right {
      position: relative;
      width: 50vw;
      height: auto;
      padding: 3%;
    }

    h6 {
      font-weight: bold;
    }

    &.bg-white {
      background-color: #fff2d7;

      h4 {
        color: #3741a0;
        font-weight: bold;
      }

      h6 {
        color: #ff847f;
      }
    }

    &.bg-blue {
      background-color: #3741a0;

      p {
        color: #ffffff;
      }

      h4 {
        color: #ffffff;
        font-weight: bold;
      }

      h6 {
        color: #ffffff;
      }

      li {
        color: #ffffff;
      }
    }

    &.bg-creme {
      background-color: #fff2d7;

      h4 {
        color: #3741a0;
        font-weight: bold;
      }

      h6 {
        color: #3741a0;
      }
    }
  }
}

.covid {
  .choose-person {
    width: 100%;

    .inputGroup {
      background-color: #fff;
      display: block;
      margin: 10px 0;
      position: relative;

      label {
        padding: 20px 10px;
        width: 100%;
        display: block;
        text-align: left;
        color: #3c454c;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: color 200ms ease-in;
        overflow: hidden;
        border-radius: 5px;
        border: 1.5px solid $secondary;
        margin-bottom: 4%;

        &:before {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          content: "";
          background-color: #20276a;
          // background-color: #5562eb;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale3d(1, 1, 1);
          transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
          opacity: 0;
          z-index: -1;
        }

        &:after {
          width: 25px;
          height: 25px;
          content: "";
          border: 2px solid #d1d7dc;
          background-color: #fff;
          background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
          background-repeat: no-repeat;
          background-position: 0px 0px;
          border-radius: 50%;
          z-index: 2;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          transition: all 200ms ease-in;
        }
      }

      input:checked ~ label {
        color: #fff;

        &:before {
          transform: translate(-50%, -50%) scale3d(56, 56, 1);
          opacity: 1;
        }

        &:after {
          // background-color: #54e0c7;
          // border-color: #54e0c7;

          background-color: #20276a;
          border-color: #20276a;
        }
      }

      input {
        width: 32px;
        height: 32px;
        order: 1;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        visibility: hidden;
      }
    }

    .plan-btn {
      width: 100%;
    }
  }

  .choose-person,
  .choose-person::before,
  .choose-person::after {
    box-sizing: inherit;
  }

  .plan-your-trip {
    min-height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .covid-title {
      font-weight: 800;
      color: white;
      font-size: 45px;
      line-height: 1.2;

      span {
        font-size: 25px;
        line-height: 1.2;
      }
    }

    img {
      object-fit: cover;
      position: absolute;
    }

    .choice_labels {
      width: 100%;
      height: 400px;
      background-color: red;
    }
  }

  .covid-banner {
    min-height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .covid-title {
      font-weight: 800;
      color: white;
      font-size: 45px;
      line-height: 1.2;
    }

    img {
      object-fit: cover;
      position: absolute;
    }
  }

  .home-quote {
    &::before {
      display: none;
    }
    .form-content {
      color: #878a91 !important;
    }
  }

  .blog-title {
    font-weight: 700;
  }

  .quote-title {
    font-weight: 800;
    font-size: 34px;
  }
  .service-title {
    position: relative;
  }

  .service-title::before,
  .service-title::after {
    content: "";
    position: absolute;
    height: 2px;
    top: 46%;
    width: calc(50% - 170px);
    background-color: #cbcbcc;
    left: 0px;
  }

  .service-title::after {
    right: 0px;
    left: unset;
  }
}

.account-btn {
  opacity: 0.5;

  .flag {
    width: 100px;
    height: 100px;
  }
}

.compare-table {
  width: 25%;
}

.pagination-btn {
  position: absolute;
  top: 50%;
}

.article-blurb {
  min-height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-image: linear-gradient(
    rgba(38, 38, 38, 0),
    rgba(82, 82, 82, 0.7),
    rgba(37, 37, 37, 0.88),
    #070707
  );
}

.covid-quote {
  font-size: 80px;
}

.quote-parent {
  height: 80px;
}

.header {
  min-height: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &::before {
    content: "";
    background-color: rgba(#9797aa, 0.2);
  }

  .container {
    position: relative;
    z-index: 10;
  }

  .header-cover {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: fit;
  }

  .header-title {
    // padding: 1% 0 0 0;
    color: white;
    font-size: 40px;
    line-height: 1.5;
    margin-left: 1.5%;
    // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.9);
    color: $secondary;
  }

  .header-subtitle {
    color: #20276a;
    // color: white;
    font-size: 20px;
    margin-left: 2%;
    // text-shadow: 0 1px 0 #20276a;
  }
}

.quote-form {
  border-radius: 10px;
  background-color: white;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  select {
    max-width: 270px;
  }

  &.bx-shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }

  select {
    background-color: white !important;
    color: #000000 !important;
    cursor: pointer;
    background: white;
    color: black;
  }

  .form-control::placeholder {
    font-weight: bold;
  }

  .quote-label {
    color: $primary;
    font-size: 14px;

    &.sm {
      font-size: 10px;
    }
  }

  [type="checkbox"]:not(:checked) + div {
    color: $dark !important;
  }
  [type="checkbox"]:checked + div:before,
  [type="checkbox"]:not(:checked) + div:before {
    border: 2px solid $blue !important;
  }
  [type="checkbox"]:checked + div:after,
  [type="checkbox"]:not(:checked) + div:after {
    background: $blue !important;
  }

  [type="radio"]:not(:checked) + div {
    color: $blue !important;
  }
  [type="radio"]:checked + div:before,
  [type="radio"]:not(:checked) + div:before {
    border: 2px solid $blue !important;
  }
  [type="radio"]:checked + div:after,
  [type="radio"]:not(:checked) + div:after {
    background: $blue !important;
  }

  .input {
    border-radius: 2.5px;
    border: solid 0.5px #e0e7ff;
    background-color: rgba(224, 231, 255, 0.2);
  }

  .option-label::before {
    input {
      position: absolute;
      right: 0px;
    }
  }

  .dependant-add-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.submit-btn {
  font-weight: bold !important;
}

.quote-header {
  .form-control {
    color: white;
  }

  .form-control option {
    color: $dark;
  }

  .quote-form {
    background-color: transparent;

    [type="checkbox"]:not(:checked) + div {
      color: white !important;
    }
    [type="checkbox"]:checked + div:before,
    [type="checkbox"]:not(:checked) + div:before {
      border: 2px solid $secondary !important;
    }
    [type="checkbox"]:checked + div:after,
    [type="checkbox"]:not(:checked) + div:after {
      background: $secondary !important;
    }

    [type="radio"]:not(:checked) + div {
      color: $secondary !important;
    }
    [type="radio"]:checked + div:before,
    [type="radio"]:not(:checked) + div:before {
      border: 2px solid $secondary !important;
    }
    [type="radio"]:checked + div:after,
    [type="radio"]:not(:checked) + div:after {
      background: $secondary !important;
    }

    [type="checkbox"]:checked + div,
    [type="checkbox"]:not(:checked) + div {
      color: $secondary;
    }
  }

  .dependants-title {
    color: #c4c4c4 !important;
    // font-weight: bold;
    // font-size: 18px;
  }

  .quote-label {
    color: white !important;
  }

  .header-title {
    font-size: 40px;
    color: $secondary;
  }

  .dependant-add-btn {
    color: white !important;
  }
}

.betlist-options {
  .option-label {
    margin-right: 3rem !important;
  }
}

.quote-form-sm {
  .quote-label {
    color: $primary;
    font-size: 13px;
    margin-right: 0px !important;
  }

  .input {
  }

  .option-label::before {
    input {
      position: absolute;
      right: 0px;
    }
  }

  .dependant-add-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .col-4 {
    // padding-left: 5px;
    // padding-right: 5px;
  }

  .it-232 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .button-list {
    justify-content: space-between !important;
    text-align: left !important;
  }

  .dependant-add-btn {
    font-size: 13px;
  }

  .betlist-options {
    label {
      min-width: 45%;
    }
  }

  .submit-btn {
    font-size: 13px;
  }

  .option-label {
    margin-right: 0px !important;
    div {
      font-size: 13px;
    }
  }

  .dependants-title {
    // font-weight: bold;
    font-size: 16px;
  }

  .quote-form {
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .header-title {
    font-size: 25px;
  }
  .header-subtitle {
    font-size: 14px;
  }
  .header-cover {
    top: 0px;
    height: 210px;
  }
}

.nowrap {
  white-space: nowrap;
}

.plan {
  .option-label {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 10px !important;
    font-size: 17px;
    // font-weight: normal;

    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="checkbox"]:checked + div,
    [type="checkbox"]:not(:checked) + div {
      position: relative;
      padding-left: 20px !important;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: $blue;
    }
    [type="checkbox"]:checked + div:before,
    [type="checkbox"]:not(:checked) + div:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 2px solid #9799ea;
      border-radius: 100%;
      background: transparent;
    }
    [type="checkbox"]:checked + div:after,
    [type="checkbox"]:not(:checked) + div:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #9799ea;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="checkbox"]:not(:checked) + div:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="checkbox"]:checked + div:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + div,
    [type="radio"]:not(:checked) + div {
      position: relative;
      padding-left: 50px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #9799ea;
    }
    [type="radio"]:checked + div:before,
    [type="radio"]:not(:checked) + div:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 2px solid #9799ea;
      border-radius: 100%;
      background: transparent;
    }
    [type="radio"]:checked + div:after,
    [type="radio"]:not(:checked) + div:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #9799ea;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + div:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + div:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
